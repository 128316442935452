<template>
  <button
    type="button"
    class="pl0 pr10 center-xs bg-cl-transparent brdr-none flex flex-col middle-xs"
    :class="isOpen ? '' : 'cl-gray'"
    @click="openSidebarMenuCustom"
    :aria-label="$t('Open menu')"
    data-testid="menuButton"
  >
    <i class="icon-btn_menu icon-size" />
  </button>
</template>

<script>
import { mapState } from 'vuex'
import HamburgerIcon from '@vue-storefront/core/compatibility/components/blocks/Header/HamburgerIcon'

export default {
  mixins: [HamburgerIcon],
  computed: {
    ...mapState({
      isAuthElem: state => state.ui.isAuthElem
    })
  },
  methods: {
    openSidebarMenuCustom () {
      if (this.isAuthElem) {
        this.$bus.$emit('modal-hide', 'modal-signup')
      }
      this.$store.commit('ui/setSidebar', !this.isOpen)
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-size {
  font-size: 35px !important;
}
</style>

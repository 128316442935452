<template>
  <div class="flex middle-xs">
    <router-link :to="localizedRoute('/i/kontakt')" class="cl-black" exact>
      <i class="icon-phone fs15" />
      <span class="pl5 h5 sans-serif"/>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'ContactIcon'
}
</script>

<style lang="scss" scoped>

</style>

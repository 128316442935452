<template>
  <div class="relative">
    <button
      type="button"
      :aria-label="$t('Open search panel')"
      class="bg-cl-transparent brdr-none inline-flex p0"
      @click="toggleSearchpanel"
      data-testid="openSearchPanel"
    >
      <i class="icon-search" />
    </button>
    <!-- <search-panel-desktop class="search" @blur="onBlurEvent" @focus="onFocusEvent" :class="[ isSearchClosed ? 'closed-search' : 'open-search' ]" /> -->
  </div>
</template>

<script>
import SearchIcon from '@vue-storefront/core/compatibility/components/blocks/Header/SearchIcon'
const SearchPanelDesktop = () => import(/* webpackChunkName: "vsf-search-panel-desktop" */ 'theme/components/core/blocks/SearchPanel/SearchPanelDesktop.vue')
export default {
  data () {
    return {
      isSearchClosed: true
    }
  },
  mixins: [SearchIcon],
  components: {
    SearchPanelDesktop
  },
  methods: {
    onBlurEvent (e) {
      if (!e) {
        this.isSearchClosed = true
      }
    },
    onFocusEvent (e) {
      this.isSearchClosed = false
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  transition: 0.3s all ease;
  top: 9px;
  right: -10px;
  position: absolute;
  &.open-search {
    width: 300px;
  }
  &.closed-search {
    @media screen and (min-width: 1350px){
      width: 300px;
    }
    width: 35px;
  }
}
.fs25 {
  font-size: 25px;
}
</style>

<template>
  <div class="header" :class="(!isCheckoutPage && !isThankYouPage) ? 'basic-header' : 'checkout-header'">
    <!-- Add Organization rich snippet -->
    <div class="hidden" itemscope itemtype="http://schema.org/Organization">
      <link itemprop="url" href="https://E-Garderobe.pl/">
      <div itemprop="contactPoint" itemscope itemtype="http://schema.org/ContactPoint">
        <span itemprop="telephone">+48 698-136-499</span>
        <span itemprop="email">info@e-garderobe.pl</span>
        <span itemprop="contactType">customer service</span>
      </div>
    </div>
    <header
      id="main-header"
      class="fixed w-100 bg-cl-primary"
      :class="{ 'is-visible': navVisible }"
    >
      <div class="top-bar container-fluid" v-if="!isCheckoutPage && !isThankYouPage">
        <top-bar />
      </div>
      <div class="container header-container">
        <div class="row between-xl middle-xs" v-if="!isCheckoutPage && !isThankYouPage">
          <div class="around-xs start-sm col-xs-3 col-md-2 flex">
            <hamburger-icon class="mobile-padding visible-xs-and-tablet pointer" />
            <account-icon class="mobile-padding icon pointer visible-xs-and-tablet" />
            <search-icon class="pointer hidden-xs-and-tablet flex" />
            <div class="pointer pl30 flex hidden-xs-and-tablet" @click="showLanguagesModal()">
              <span class="h5 language-modal-label">{{ $t('Shop in') }}: {{ $t(selectedCountryInStoreCode) }} ({{ $store.state.storeView.i18n.currencySign }})</span>
              <img class="language-modal" :src="`/assets/country/${selectedCountry}.png`" alt="country flag" width="30px" height="30px">
            </div>
          </div>
          <div class="col-xs-6 col-md-8 center-xs flex">
            <logo class="center-md" width="155" height="35" />
          </div>
          <div class="around-xs end-sm col-xs-3 col-md-2 flex middle-xs">
            <router-link :to="localizedRoute('/selling')">
              <button class="header-sell-btn hidden-xs-and-tablet">
                {{ $t('Sell your products') }}
              </button>
            </router-link>
            <account-icon class="icon pointer hidden-xs-and-tablet" />
            <wishlist-icon class="mobile-padding pointer" />
            <microcart-icon class="mobile-padding icon pointer" />
          </div>
          <div class="col-xs-12 visible-xs-and-tablet">
            <search-panel-mobile />
          </div>
        </div>
        <div
          class="checkout-page row between-xs middle-xs px20 m0"
          v-if="isCheckoutPage || isThankYouPage"
        >
          <div class="col-xs-2 flex middle-xs start-xs visible-xs-and-tablet">
            <router-link :to="localizedRoute('/')">
              <span class="arrow" />
            </router-link>
          </div>
          <div class="col-xs-7 col-md-3 middle-xs center-xs start-md">
            <logo class="middle-xs" width="auto" :height="$device.isMobile? '24px' : '41px'" />
          </div>
          <div class="col-xs-3 flex middle-xs end-xs">
            <img src="/assets/footer/certum.png" width="65" height="auto" alt="Certum Logo">
          </div>
        </div>
      </div>
      <desktop-navigation v-if="!isCheckoutPage && !isThankYouPage" class="hidden-xs-and-tablet brdr-top-1 brdr-bottom-1 brdr-cl-white" />
    </header>
    <modal-switcher v-if="loadLanguagesModal" :selected-country-in-store-code="selectedCountryInStoreCode" />
    <div class="header-placeholder" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CurrentPage from 'theme/mixins/currentPage'
import AccountIcon from 'theme/components/core/blocks/Header/AccountIcon'
import Logo from 'theme/components/core/Logo'
import MicrocartIcon from 'theme/components/core/blocks/Header/MicrocartIcon'
import SearchIcon from 'theme/components/core/blocks/Header/SearchIcon'
import WishlistIcon from 'theme/components/core/blocks/Header/WishlistIcon'
import DesktopNavigation from 'theme/components/core/blocks/Header/DesktopNavigation'
import HamburgerIcon from 'theme/components/core/blocks/Header/HamburgerIcon';
import HomeIcon from 'theme/components/core/blocks/Header/HomeIcon'
import TopBar from 'theme/components/core/blocks/Header/TopBar'
import ContactIcon from 'theme/components/core/blocks/Header/ContactIcon'
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import config from 'config'
import { redirectToStore, selectCountryInStoreCode } from 'theme/helpers/languageHelper'

const ModalSwitcher = () => import(/* webpackChunkName: "vsf-languages-modal" */ 'theme/components/core/blocks/Switcher/Language.vue')
const SearchPanelMobile = () => import(/* webpackChunkName: "vsf-search-panel-mobile" */ 'theme/components/core/blocks/SearchPanel/SearchPanelMobile.vue')

export default {
  name: 'Header',
  components: {
    AccountIcon,
    Logo,
    MicrocartIcon,
    HamburgerIcon,
    SearchPanelMobile,
    HomeIcon,
    SearchIcon,
    WishlistIcon,
    DesktopNavigation,
    TopBar,
    ContactIcon,
    ModalSwitcher
  },
  mixins: [CurrentPage],
  data () {
    return {
      navVisible: true,
      isScrolling: false,
      scrollTop: 0,
      lastScrollTop: 0,
      navbarHeight: 71,
      loadLanguagesModal: false,
      selectedCountry: 'PL',
      selectedCountryInStoreCode: null
    }
  },
  computed: {
    ...mapState({
      isOpenLogin: state => state.ui.signUp,
      filter: state => state.ui.mobileFilter,
      currentUser: state => state.user.current,
      isOpen: state => state.ui.newsletterPopup
    }),
    currentStoreView () {
      return currentStoreView().i18n
    }
  },
  beforeMount () {
    let storeCode = localStorage.getItem('savedStoreCode')
    if (!this.$route.path.includes('/create-password')) {
      if (storeCode) {
        storeCode = JSON.parse(storeCode)
        let store = config.storeViews[storeCode]
        if (store && store.url) {
          if (storeCode !== this.$store.state.storeView.storeCode) {
            redirectToStore(store)
          }
        }
      } else if (navigator.language && navigator.language.includes('de') && this.$store.state.storeView.i18n.defaultLanguage !== 'DE') {
        let store = config.storeViews['pln_de']
        redirectToStore(store)
      } else if (navigator.language && navigator.language.includes('pl') && this.$store.state.storeView.i18n.defaultLanguage !== 'PL') {
        let store = config.storeViews['pln_pl']
        redirectToStore(store)
      }
    }
    window.addEventListener(
      'scroll',
      () => {
        this.isScrolling = true
      },
      { passive: true }
    )

    setInterval(() => {
      if (this.isScrolling) {
        this.$bus.$emit('has-scrolled')
        this.hasScrolled()
        this.isScrolling = false
      }
    }, 250)
    this.$bus.$on('modal-show', this.toggleModalSwitcher)
  },
  mounted () {
    this.selectDefaultCountry()
    this.selectCountryInStoreCode()
  },
  beforeDestroy () {
    this.$bus.$off('modal-show', this.toggleModalSwitcher)
  },
  methods: {
    selectCountryInStoreCode () {
      const countryFromLocalStorage = selectCountryInStoreCode()
      if (countryFromLocalStorage) {
        this.selectedCountryInStoreCode = selectCountryInStoreCode()
        return
      }
      this.selectedCountryInStoreCode = this.$store.state.storeView.tax.defaultCountry
    },
    selectDefaultCountry () {
      let storedItem = localStorage && localStorage.getItem('savedStoreCode')
      if (storedItem) {
        storedItem = JSON.parse(storedItem)
        let store = config.storeViews[storedItem]
        this.selectedCountry = store.i18n.defaultCountry
        this.$store.commit('ui/setSelectedCountry', this.selectedCountry)
      }
      if (this.$store.state.ui.selectedCountry) this.selectedCountry = this.$store.state.ui.selectedCountry
    },
    showLanguagesModal () {
      this.$bus.$emit('modal-show', 'modal-switcher')
    },
    toggleModalSwitcher (type) {
      if (type === 'modal-switcher') {
        this.loadLanguagesModal = true
      }
    },
    gotoAccount () {
      this.$bus.$emit('modal-toggle', 'modal-signup')
    },
    hasScrolled () {
      this.scrollTop = window.scrollY
      if (
        this.scrollTop > this.lastScrollTop &&
        this.scrollTop > this.navbarHeight
      ) {
        this.navVisible = false
      } else {
        this.navVisible = true
      }
      this.lastScrollTop = this.scrollTop
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
@import '~theme/css/base/global_vars';
$z-index: map-get($z-index, header) + 1;
$color-icon-hover: color(secondary, $colors-background);
$main: color(main);
$white: color(white);
$gray: color(gray);
$pink: color(#F8B1B1);
.pwa-styles {
  @media (display-mode: standalone) {
    padding-bottom: 15px;
  }
}
header {
  top: -40px;
  z-index: 6;
  background-color: #f5f5f5;
  transition: top 0.2s ease-in-out;
  &.is-visible {
    top: 0;
  }
  @media (max-width: 1025px) {
    border: 0;
    background: $white;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
  }
}
.navigation-mobile {
  @media (max-width: 1025px) {
    display: flex;
  }
}
.flex-nowrap {
  flex-wrap: nowrap;
}
.basic-header {
  height: 134px;
  @media (max-width: 1025px) {
    height: 140px;
  }
}
.checkout-header {
  height: 56px;
  @media (max-width: 1025px) {
    height: 50px;
    header {
      top: 0;
      bottom: unset;
      box-shadow: 0 1px 4px #00000029;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 9px;
      border-bottom-right-radius: 9px;
      .container {
        padding: 0;
      }
    }
    .checkout-page {
      height: 50px;
      padding: 0 15px;
    }
    .arrow {
      color: $gray;
      width: 28px;
      height: 28px;
      border: 1px solid $gray;
      border-radius: 50%;
      display: flex;
      position: relative;
      &::after {
        content: '';
        position: relative;
        top: 50%;
        left: 57%;
        height: 8px;
        width: 8px;
        display: flex;
        border-left: 1px solid $gray;
        border-bottom: 1px solid $gray;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }
}
.icon-size {
  font-size: 8px;
}
.top-bar {
  background-color: #2D2D2D;
  height: 40px;
  text-align: center;
}
.right-icons {
  //for edge
  float: right;

}
.header-placeholder {
  height: 71px;
  @media (max-width: 991px) {
    height: 0;
  }
}
.header-container {
  // padding-left: 180px;
  // padding-right: 180px;
  // @media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 767px) {
  //   padding-left: 0px;
  //   padding-right: 0px;
  // }
}
.links {
  text-decoration: underline;
}
.flex-basis-100 {
  flex-basis: 100%;
}
.language-modal {
  &-label {
    font-size: 12px;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
  &:hover {
    border: 1px solid #000;
  }
  padding: 1px;
  margin: 5px;
  border: 1px solid #f5f5f1;
  border-radius: 50px;
}
.mobile-padding {
  // padding-left: 10px;
  // padding-right: 10px;
}
.header-sell-btn {
  height: 38px;
  padding-left: 32px;
  padding-right: 32px;
  color: white;
  background-color: black;
  border: none;
  border-radius: 20px 20px;
  text-transform: uppercase;
  letter-spacing: 1.23px;
  font-size: 14px;
  font-weight: bold;
  margin-right: 15px;
  white-space: nowrap;
}
</style>

<template>
  <transition :name="transitionName">
    <div
      class="modal"
      :class="imageModal ? 'image-z-index' : ''"
      v-if="isVisible"
      ref="modal"
    >
      <slot name="buttons" />
      <i
        v-if="imageModal"
        slot="close"
        class="image-close-button icon-close"
        @click="close"
        data-testid="closeModalButton"
      />
      <!--      <div class="modal-wrapper">-->
      <!--        <div class="modal-center">-->
      <div class="modal-backdrop" @click="close" />
      <div class="bg-cl-primary" ref="modal-content" :class="modalSize ? 'modal-container-size' : 'modal-container'" :style="style">
        <header class="modal-header py25 px65 h1 cl-white serif weight-300 bg-cl-black" v-if="$slots.header">
          <slot name="header" />
          <i
            slot="close"
            class="modal-close icon-close h5 cl-black"
            @click="close"
            data-testid="closeModalButton"
          />
        </header>
        <div class="modal-content bg-cl-primary pt30 pb60 px65" :class="imageModal ? 'image-paddnigs' : ''" v-if="$slots.content">
          <slot name="content" />
        </div>
        <slot />
      </div>
    </div>
    <!--      </div>-->
    <!--    </div>-->
  </transition>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import onEscapePress from '@vue-storefront/core/mixins/onEscapePress'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'

export default {
  name: 'Modal',
  data () {
    return {
      isVisible: false
    }
  },
  watch: {
    isVisible (state) {
      if (state && !this.imageModal) {
        this.$nextTick(() => {
          disableBodyScroll(this.$refs['modal']);
        })
      } else {
        clearAllBodyScrollLocks();
      }
    }
  },
  methods: {
    onHide (name, state, params) {
      if (this.isAuthElem) {
        this.$store.commit('ui/clearAuthModals')
      }
      return name === this.name ? this.toggle(false) : false
    },
    onShow (name, state, params) {
      return name === this.name ? this.toggle(true) : false
    },
    onToggle (name, state, params) {
      if (name === this.name) {
        state = typeof state === 'undefined' ? !this.isVisible : state
        this.toggle(state)
      }
    },
    onEscapePress () {
      this.close()
    },
    ...mapMutations('ui', [
      'setOverlay'
    ]),
    toggle (state) {
      if (this.isAuthElem) {
        this.$store.commit('ui/clearAuthModals')
      }
      this.isVisible = state
      state ? this.setOverlay(state) : setTimeout(() => this.setOverlay(state), this.delay)
    },
    close () {
      this.toggle(false)
    }
  },
  beforeMount () {
    this.$bus.$on('modal-toggle', this.onToggle)
    this.$bus.$on('modal-show', this.onShow)
    this.$bus.$on('modal-hide', this.onHide)
  },
  beforeDestroy () {
    this.$bus.$off('modal-toggle', this.onToggle)
    this.$bus.$off('modal-show', this.onShow)
    this.$bus.$off('modal-hide', this.onHide)
  },
  mixins: [onEscapePress],
  props: {
    name: {
      required: true,
      type: String
    },
    delay: {
      required: false,
      type: Number,
      default: 300
    },
    modalSize: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 0
    },
    transitionName: {
      type: String,
      default: 'fade-in-down'
    },
    imageModal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({
      isAuthElem: state => state.ui.isAuthElem
    }),
    style () {
      return this.width ? `width: ${this.width}px` : false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/base/global_vars';
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$z-index-modal: map-get($z-index, modal);
$white: color(white);

.image-paddnigs {
  padding: 0 !important;
}
.image-z-index {
  z-index: 12;
}
.image-close-button {
    position: absolute;
    z-index: 100;
    bottom: 4%;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    left: calc(50% - 22px);
    background: white;
}
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: $z-index-modal + 1;

  .modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 550px;
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
    z-index: $z-index-modal+1;
    background: $white;
    @media (max-width: 767px) {
      overflow-y: auto;
      background: $white;
      width: 90% !important;
    }
  }
  .modal-container-size {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 945px;
    margin: 0 auto;
    max-width: 100%;
    max-height: 100%;
    z-index: $z-index-modal+1;
    background: $white;
    @media (max-width: 767px) {
      background: $white;
      width: 100% !important;
      height: 100%;
      overflow-y: auto;
    }
  }
  .modal-header {
    position: relative;

    > * {
        margin: 0;
    }

    @media (max-width: 600px) {
      padding: 25px 20px;
    }
  }

  .modal-content {
    overflow-y: auto;
    @media (max-width: 600px) {
      padding: 30px 20px;
    }
  }

  .modal-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .modal-close{
    cursor: pointer;
  }
  .modal-backdrop{
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height: 100%;
  }
}
</style>

<template>
  <div
    class="inline-flex relative dropdown"
    data-testid="accountButton"
    @click.self="goToAccount();showMenu = true;"
    @keyup.enter="goToAccount"
    tabindex="1"
    role="button"
    @mouseover="showMenu = true"
    @mouseout="showMenu = false"
    :aria-label="$t('Open my account')"
  >
    <button
      type="button"
      class="bg-cl-transparent brdr-none p0 cl-black flex direction middle-xs"
    >
      <i class="icon-user account-icon" :class="isAuthElem || isMyAccount ? '': 'colors'" />
      <span class="sans-serif no-wrap account-text" :class="isAuthElem || isMyAccount ? '': 'colors'" />
    </button>
    <!-- <no-ssr> -->
    <div v-show="currentUser" :class="['dropdown-content bg-cl-primary align-left sans-serif lh20 weight-400', !showMenu ? 'dropdown-content__hidden' : '']">
      <div class="py5">
        <div v-for="(page, index) in navigation" :key="index" @click="notify(page.title)">
          <router-link @click.native="showMenu = false" class="no-underline h5 cl-black block py10 px15" :to="localizedRoute(page.link)">
            {{ page.title }}
          </router-link>
        </div>
      </div>
      <div class="py5 brdr-top-1 brdr-cl-bg-secondary">
        <a href="#" class="no-underline block py10 px15 cl-black h5" @click.prevent="logout">{{ $t('Logout') }}</a>
      </div>
    </div>
    <!-- </no-ssr> -->
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
// import EventBus from '@vue-storefront/core/compatibility/plugins/event-bus'
import NoSSR from 'vue-no-ssr'
import AccountIcon from '@vue-storefront/core/compatibility/components/blocks/Header/AccountIcon'
import CurrentPage from 'theme/mixins/currentPage'

export default {
  mixins: [AccountIcon, CurrentPage],
  components: {
    'no-ssr': NoSSR
  },
  data () {
    return {
      showMenu: false,
      navigation: [
        { title: this.$t('My profile'), link: '/my-account' },
        { title: this.$t('My shipping details'), link: '/my-account/shipping-details' },
        { title: this.$t('My subscriptions'), link: '/my-account/subscriptions' },
        { title: this.$t('My active subscriptions'), link: '/my-account/active-subscriptions' },
        { title: this.$t('My orders'), link: '/my-account/orders' },
        { title: this.$t('Sell'), link: '/my-account/my-selling-products' },
        { title: this.$t('My Recently viewed'), link: '/my-account/recently-viewed' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isLogged: 'user/isLoggedIn'
    }),
    ...mapState({
      isAuthElem: state => state.ui.isAuthElem
    })
  },
  methods: {
    notify (title) {
      if (title === 'My loyalty card' || title === 'My product reviews') {
        this.$store.dispatch('notification/spawnNotification', {
          type: 'warning',
          message: this.$t('This feature is not implemented yet! Please take a look at https://github.com/DivanteLtd/vue-storefront/issues for our Roadmap!'),
          action1: { label: this.$t('OK') }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$color-main: color(main);
$gray: color(gray);
.direction {
  @media (max-width: 1024px) {
    flex-direction: column;
  }
}
.colors {
  color: black;
}
.account-icon {
  font-size: 45px;
  @media (max-width: 767px) {
  }
}
.account-text {
  // padding-left: 5px;
  font-size: 14px;
}
.dropdown {
  button {
    // width: 40px;
    pointer-events: none;
  }
  &:focus {
    outline: 0;
  }
  .dropdown-content {
    display: none;
    position: absolute;
    right: 0;
    top: 100%;
    width: 200px;
    z-index: 1;
  }

  a {
    &:hover,
    &:focus {
      background-color: $color-main;
      color: white;
      opacity: 1;
    }

  }

  @media (min-width: 768px) {
    &:hover .dropdown-content:not(.dropdown-content__hidden),
    &:focus .dropdown-content:not(.dropdown-content__hidden) {
      display: block;
    }
  }
}
</style>

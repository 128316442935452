import config from 'config'
import rootStore from '@vue-storefront/core/store'

interface Store {
  id: string,
  name: string,
  websiteId: number
}

/**
 * @param categoryPath
 * @param getSelectedCountry
 */
export function getSelectedCountry (allowedCountries, currentCountry): Store {
  return allowedCountries && allowedCountries.find(country => country.id === currentCountry)
}

/**
 * todo
 * @param storeId
 * @param
 */
export function getStoreCodeRoute (storeId) {
  let currentProduct = rootStore.getters['product/getCurrentProduct']
  let currentCategory = rootStore.getters['category-next/getCurrentCategory']
  if (currentProduct && currentProduct.store_view_urls) {
    return currentProduct.store_view_urls[`store_${storeId}`]
      ? currentProduct.store_view_urls[`store_${storeId}`]
      : currentProduct.store_view_urls[`store_0`]
  } else if (currentCategory && currentCategory.store_view_urls) {
    return currentCategory.store_view_urls[`store_${storeId}`]
      ? currentCategory.store_view_urls[`store_${storeId}`]
      : currentCategory.store_view_urls[`store_0`]
  } else return ''
}

/**
 * Get first avliable store for selected country
 * @param categoryPath
 * @param getSelectedCountry
 */
export function getStore (allowedCountries, currentCountry): any {
  let selectedCountry = getSelectedCountry(allowedCountries, currentCountry)
  const { mapStoreUrlsFor = [] } = config.storeViews
  let fisrtMatch = null
  for (let storeViewProp of mapStoreUrlsFor) {
    let currStoreView = config.storeViews[storeViewProp]
    if (!currStoreView) continue
    if (!fisrtMatch && (currStoreView.websiteId === selectedCountry.websiteId)) {
      fisrtMatch = currStoreView
    }
    if ((currStoreView.websiteId === selectedCountry.websiteId) && (currStoreView.i18n.defaultLanguage === rootStore.state.storeView.i18n.defaultLanguage)) {
      return currStoreView
    }
  }
  return fisrtMatch
}

/**
 * Select contry from getStore()
 * @param store
 */
export function redirectToStore (store): void {
  localStorage.setItem('savedStoreCode', JSON.stringify(store.storeCode))
  if (store && store.url) {
    let countryUrl = store.url + '/' + getStoreCodeRoute(store.storeId)
    window.location.href = countryUrl + '?sc=' + store.storeCode
  }
}

/**
 * Select contry from getStore()
 * @param allowedCountries
 * @param getSelectedCountry
 */
export function selectCountry (allowedCountries, currentCountry): void {
  let store = getStore(allowedCountries, currentCountry)
  let selectedCountry = getSelectedCountry(allowedCountries, currentCountry)
  if (selectedCountry) localStorage.setItem('savedCountry', JSON.stringify(selectedCountry))
  redirectToStore(store)
}

export function selectCountryInStoreCode () {
  let storedItem: any = localStorage && localStorage.getItem('savedCountry')
  if (!storedItem) return null
  storedItem = JSON.parse(storedItem)
  return storedItem.id
}

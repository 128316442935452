<template>
  <router-link
    :to="localizedRoute('/')"
    :title="$t('Home Page')"
    class="no-underline flex flex-col middle-xs"
    :class="isHomePage ? '' : 'cl-gray'"
  >
    <i class="icon-home h3 icon-size" />
    <span class="visible-md h6">Home</span>
  </router-link>
</template>

<script>
import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import CurrentPage from 'theme/mixins/currentPage'

export default {
  mixins: [CurrentPage],
  data () {
    const storeView = currentStoreView()
    return {
      defaultTitle: storeView.seo.defaultTitle ? storeView.seo.defaultTitle : config.seo.defaultTitle
    }
  }
}
</script>

<style scoped>

</style>

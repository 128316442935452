import { render, staticRenderFns } from "./DesktopNavigation.vue?vue&type=template&id=ed0f7bac&scoped=true&"
import script from "./DesktopNavigation.vue?vue&type=script&lang=js&"
export * from "./DesktopNavigation.vue?vue&type=script&lang=js&"
import style0 from "./DesktopNavigation.vue?vue&type=style&index=0&id=ed0f7bac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed0f7bac",
  null
  
)

export default component.exports
<template>
  <nav class="" @mouseleave="activeSubcategory = true, heeadBarShow = false">
    <div class="container flex between-xs py10">
      <div v-for="(category, index) in categoriesMap[categoryIdsMap.rootCategoryId]" :key="category.id" @mouseenter="hover(index)" class="menu__item pointer middle-xs">
        <div @mouseenter="hoverSubcategory(category.id)">
          <router-link @click.native="heeadBarShow = false" class="menu__item item-hover h6 no-underline cl-gray uppercase" :to="category.id !== categoryIdsMap.subscriptionCategoryId ? categoryLink(category) : localizedRoute($t('abonament-modowy-wybierz-pakiet'))">
            {{ category.name }}
          </router-link>
        </div>
      </div>
      <div @mouseenter="heeadBarShow = false" class="menu__item pointer middle-xs">
        <router-link :to="localizedRoute('/i/' + $t('how-it-works-sell'))" class="menu__item item-hover h6 no-underline cl-gray uppercase">
          {{ $t("How it works") }}
        </router-link>
      </div>
      <div @mouseenter="heeadBarShow = false" class="menu__item pointer middle-xs">
        <router-link :to="localizedRoute('/i/' + $t('showroom'))" class="menu__item item-hover h6 no-underline cl-gray uppercase">
          E-garderobe
        </router-link>
      </div>
      <transition name="fade">
        <!-- Sekcja dla Projektanci ot A - Z -->
        <div v-if="heeadBarShow" class="megamenu__submenu absolute pb20 pointer">
          <!-- Sekcja dla popularne okazje -->
          <div v-if="activeId === categoryIdsMap.occasionCategoryId" class="col-xs-2 mt30 pt5 p0">
            <div class="pt5 uppercase sub-category-header p0">
              {{ $t(subcategoryHeaderName) }}
            </div>
            <div v-for="(category, index) in categoriesMap[categoryIdsMap.occasionCategoryId] ? categoriesMap[categoryIdsMap.occasionCategoryId].find(x => x.id === categoryIdsMap.popularOccasionCategoryId).children_data : []" v-show="index <= 8" :key="index" class="submenu-item relative">
              <router-link @click.native="heeadBarShow = false" :to="categoryLink(category)" class="py10 flex h5 submenu-text no-underline">
                {{ category.name }} <span class="arrow-hover">➜</span>
              </router-link>
            </div>
          </div>
          <!-- Tutaj SubCat z magento -->
          <div v-if="activeId !== categoryIdsMap.occasionCategoryId && activeId !== categoryIdsMap.subscriptionCategoryId && activeId !== categoryIdsMap.marketplaceCategoryId && activeId !== categoryIdsMap.rootDesignerCategoryId" class="col-xs-2 mt30 pt5 p0">
            <div class="pt5 uppercase sub-category-header p0">
              {{ $t('Categories') }}
            </div>
            <div class="submenu-item relative" @mouseenter="subcategoryName = thirdLevelCategory.name" v-for="(thirdLevelCategory, index) in notIncludedInMenu(categoriesMap[activeSubcategory])" v-show="index <= 8" :key="thirdLevelCategory.id">
              <router-link @click.native="heeadBarShow = false" v-if="thirdLevelCategory.include_in_menu !== 0" :to="categoryLink(thirdLevelCategory)" class="py10 flex h5 submenu-text no-underline">
                {{ thirdLevelCategory.name }} <span class="arrow-hover">➜</span>
              </router-link>
            </div>
          </div>
          <!-- Tutaj SubCat z magento projektanci -->
          <template v-if="activeId === categoryIdsMap.rootDesignerCategoryId">
            <div class="col-xs-2 mt30 pt5 p0" v-for="designersType in categoriesMap[categoryIdsMap.rootDesignerCategoryId]" :key="designersType.id">
              <div class="pt5 uppercase sub-category-header p0">
                {{ designersType.name }}
              </div>
              <div class="submenu-item relative" @mouseenter="subcategoryName = designer.name" v-for="(designer, index) in designersType.children_data" :key="designer.id">
                <router-link @click.native="heeadBarShow = false" v-if="designer.include_in_menu !== 0" :to="categoryLink(designer)" class="py10 flex h5 submenu-text no-underline">
                  {{ designer.name }} <span class="arrow-hover">➜</span>
                </router-link>
              </div>
            </div>
          </template>
          <!-- Tutaj SubCat z magento dla marketplace (dodatkowy filtr) -->
          <div v-if="activeId === categoryIdsMap.marketplaceCategoryId" class="col-xs-2 mt30 pt5 p0">
            <div class="pt5 uppercase sub-category-header p0">
              {{ $t('Clothes') }}
            </div>
            <div class="submenu-item relative" @mouseenter="subcategoryName = clothes.name" v-for="(clothes, index) in clothesCategoriesForMarketplace" v-show="index <= 8" :key="clothes.id">
              <router-link @click.native="heeadBarShow = false" v-if="clothes.include_in_menu !== 0" :to="localizedRoute(clothes.url_path)" class="py10 flex h5 submenu-text no-underline">
                {{ clothes.name }} <span class="arrow-hover">➜</span>
              </router-link>
            </div>
          </div>
          <!-- Podsekcja dla kategotii Ubrania -->
          <div v-if="activeId === categoryIdsMap.mainCategoryId" class="col-xs-2 mt30 pt5 p0">
            <div class="pt5 uppercase sub-category-header p0">
              {{ $t('Designs in color') }}
            </div>
            <div v-for="(color, index) in colors" :key="index" class="submenu-item relative">
              <router-link @click.native="heeadBarShow = false" :to="localizedRoute(getMainCategory.url_path + color.link)" class="py10 flex h5 submenu-text no-underline">
                {{ color.color }} <span class="arrow-hover">➜</span>
              </router-link>
            </div>
            <p class="fs12 weight-700 start-xs">
              <router-link @click.native="heeadBarShow = false" class="ubrania-text" :to="localizedRoute('/i/' + $t('how-it-works'))">
                {{ $t('RENTAL BENEFITS') }}<span>➜</span>
              </router-link>
            </p>
          </div>
          <!-- Podsekcja dla kategorii marketplace -->
          <div v-if="activeId === categoryIdsMap.marketplaceCategoryId" class="col-xs-2 mt30 pt5 p0">
            <div v-for="(category, index) in noClothesCategoriesForMarketplace" :key="index" class="submenu-item relative">
              <router-link @click.native="heeadBarShow = false" :to="localizedRoute(category.url_path)" class="py10 flex h5 no-underline uppercase sub-category-header p0">
                {{ category.name }} <span class="arrow-hover">➜</span>
              </router-link>
            </div>
          </div>
          <!-- Podsekcja dla kategorii Okazje -->
          <div v-if="activeId === categoryIdsMap.occasionCategoryId" class="col-xs-2 mt30 pt5 p0">
            <div class="pt5 uppercase sub-category-header p0">
              {{ $t('Another occasion') }}
            </div>
            <div v-for="(category, index) in categoriesMap[categoryIdsMap.occasionCategoryId] ? categoriesMap[categoryIdsMap.occasionCategoryId].find(x => x.id === categoryIdsMap.seasonalOccasionCategoryId).children_data : []" v-show="index <= 8" :key="index" class="submenu-item relative">
              <router-link @click.native="heeadBarShow = false" :to="categoryLink(category)" class="py10 flex h5 submenu-text no-underline">
                {{ category.name }} <span class="arrow-hover">➜</span>
              </router-link>
            </div>
          </div>
          <!-- Statyczna Sekcja Abonament modowy -->
          <div v-if="activeId === categoryIdsMap.subscriptionCategoryId" class="col-xs-2 mt30 pt30 p0">
            <div v-for="(link, index) in abonamentLinks" :key="index" class="submenu-item relative">
              <router-link @click.native="heeadBarShow = false" v-show="index != 3" :to="index === 2 ? localizedRoute(getMainCategory.url_path + link.link) : localizedRoute(link.link)" class="py10 flex h5 submenu-text no-underline">
                {{ link.title }} <span class="arrow-hover">➜</span>
              </router-link>
              <a class="py10 flex h5 submenu-text no-underline" :href="link.link" v-show="index === 3" target="_blank">{{ link.title }} <span class="arrow-hover">➜</span></a>
            </div>
            <p class="fs12 weight-500 start-xs">
              {{ $t('Get an access to the endless closet by choosing one of 3 plans tailored to your needs, right at your door. No contracts or obligations - book or cancel your subscription anytime.') }}
            </p>
          </div>
          <!-- Podsekcja dla Zjęć dla każdej z kategorii -->
          <div v-if="parseBaner" class="col-xs-2 mt30 mx10 pt5 p0">
            <div class="pt5 uppercase sub-category-header p0">
              {{ $t(parseBaner.title1) }}
            </div>
            <div class="image-box">
              <router-link @click.native="heeadBarShow = false" class="image-wrapper" :to="parseBaner.link1 ? localizedRoute(parseBaner.link1) : '/home'">
                <img class="megamenu-image" v-lazy="getThumbnailPath(parseBaner.image1, getConfigSquareImageWidth, getConfigSquareImageHeight)" alt="category image">
              </router-link>
            </div>
            <div class="py10 flex h5 submenu-text start-xs no-underline">
              {{ $t('Learn More') }} <span class="arrow-hover">➜</span>
            </div>
          </div>
          <div v-if="parseBaner" class="col-xs-2 mt30 mx10 pt5 p0">
            <div class="pt5 uppercase sub-category-header p0">
              {{ $t(parseBaner.title2) }}
            </div>
            <div class="image-box">
              <router-link @click.native="heeadBarShow = false" class="image-wrapper" :to="parseBaner.link2 ? localizedRoute(parseBaner.link2) : '/home'">
                <img class="megamenu-image" v-lazy="getThumbnailPath(parseBaner.image2, getConfigSquareImageWidth, getConfigSquareImageHeight)" alt="category image">
              </router-link>
            </div>
            <div class="py10 h5 submenu-text start-xs no-underline">
              {{ $t('Learn More') }} <span class="arrow-hover">➜</span>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import i18n from '@vue-storefront/i18n'
import pickBy from 'lodash-es/pickBy'
import { formatCategoryLink } from '@vue-storefront/core/modules/url/helpers'
import groupBy from 'lodash-es/groupBy'
import { getThumbnailPath as _thumbnailHelper } from '@vue-storefront/core/helpers'
import config from 'config'

export default {
  data () {
    return {
      abonamentLinks: [
        { title: i18n.t('PURCHASE A SUBSCRIPTION'), link: '/' + i18n.t('abonament-modowy-wybierz-pakiet') },
        { title: i18n.t('AVAILABLE FOR SUBSCRIPTION'), link: i18n.t('clothes url') + '/?subscription=true' },
        { title: i18n.t('FAQ'), link: this.$t('https://faq.e-garderobe.com') }
      ],
      colors: [
        { color: this.$t('Black'), link: '?basic_color=98' },
        { color: this.$t('Blue'), link: '?basic_color=100' },
        { color: this.$t('Green'), link: '?basic_color=97' },
        { color: this.$t('Pink'), link: '?basic_color=99' },
        { color: this.$t('Red'), link: '?basic_color=102' },
        { color: this.$t('Violet'), link: '?basic_color=104' },
        { color: this.$t('White'), link: '?basic_color=96' },
        { color: this.$t('Yellow/Gold'), link: '?basic_color=95' }
      ],
      subHeadBarShow: false,
      subHeadBarShow2: false, // todo: use only subHeadBarShow
      activeId: null,
      activeSubcategory: null,
      heeadBarShow: false, // todo: use only subHeadBarShow
      subcategoryName: ''
    }
  },
  computed: {
    ...mapGetters('category-next', ['getMenuCategories']),
    ...mapGetters({
      getMenuCategories: 'category-next/getMenuCategories',
      getAvailableFilters: 'category-next/getAvailableFilters'
    }),
    getConfigSquareImageWidth () {
      return config.products.square.width
    },
    getConfigSquareImageHeight () {
      return config.products.square.height
    },
    validBaners () {
      return this.$store.state.cmsBlock.baners
    },
    getFashionSubscriptionName () {
      return this.getMenuCategories.find(x => x.id === config.server.categoryIdsMap.fashionSubscriptionCategoryId).name || 'SUBSCRIPTION'
    },
    availableFilters () {
      return pickBy(this.getAvailableFilters, (filter, filterType) => { return (filter.length && !this.$store.getters['category-next/getSystemFilterNames'].includes(filterType)) })
    },
    getDesignersLink () {
      return this.categoriesMap[config.server.categoryIdsMap.rootCategoryId].find(x => x.id === config.server.categoryIdsMap.designerCategoryId).url_path || ''
    },
    parseBaner () {
      if (this.activeId === config.server.categoryIdsMap.mainCategoryId) {
        return this.validBaners['megamenu_ubrania']
      } else if (this.activeId === config.server.categoryIdsMap.occasionCategoryId) {
        return this.validBaners['megamenu_okazje']
      } else if (this.activeId === config.server.categoryIdsMap.rootDesignerCategoryId) {
        return this.validBaners['megamenu_projektanci']
      } else if (this.activeId === config.server.categoryIdsMap.subscriptionCategoryId) {
        return this.validBaners['megamenu_abonament']
      } else if (this.activeId === config.server.categoryIdsMap.packageCategoryId) {
        return this.validBaners['megamenu_pakiety']
      } else if (this.activeId === config.server.categoryIdsMap.marketplaceCategoryId) {
        return this.validBaners['megamenu_marketplace']
      } else {
        return ''
      }
    },
    categoriesMap () {
      return this.getMenuCategories ? groupBy(this.getMenuCategories, 'parent_id') : ''
    },
    getMainCategory () {
      return this.getMenuCategories.find(cat => cat.id === config.server.categoryIdsMap.mainCategoryId)
    },
    subcategoryHeaderName () {
      if (this.activeId === config.server.categoryIdsMap.mainCategoryId) {
        return 'Categories' // todo get dynamic name from map
      } else if (this.activeId === config.server.categoryIdsMap.occasionCategoryId) {
        return 'Popular'
      } else if (this.activeId === config.server.categoryIdsMap.designerCategoryId) {
        return 'Popular Designers'
      } else {
        return ''
      }
    },
    categoryIdsMap () {
      return config.server.categoryIdsMap
    },
    noClothesCategoriesForMarketplace () {
      // return only boots, jewellery, bags
      return this.categoriesMap[this.categoryIdsMap.marketplaceCategoryId] && this.categoriesMap[this.categoryIdsMap.marketplaceCategoryId][0].children_data.filter(child => config.server.noClothesCategoriesForMarketplace.includes(child.id))
    },
    clothesCategoriesForMarketplace () {
      // without only boots, jewellery, bags
      return this.categoriesMap[this.categoryIdsMap.marketplaceCategoryId] && this.categoriesMap[this.categoryIdsMap.marketplaceCategoryId][0].children_data.filter(child => !this.noClothesCategoriesForMarketplace.includes(child))
    }
  },
  methods: {
    toggleQueryFilter (colorQuery) {
      if (this.getMainCategory) {
        this.$router.push({ path: this.categoryLink(this.getMainCategory), query: { query: colorQuery } })
      }
    },
    showLanguagesModal () {
      this.$bus.$emit('modal-show', 'modal-switcher')
    },
    closeMenu () {
      this.activeId = null
      this.activeSubcategory = null
    },
    getThumbnailPath (image, widht, height) {
      return _thumbnailHelper(image, widht, height, 'banners')
    },
    hover () {
      this.heeadBarShow = true
    },
    nameToShow () {
      return this.subcategoryName || (this.currentSubcategory && this.currentSubcategory.name)
    },
    currentSubcategory () {
      return this.getMenuCategories ? this.getMenuCategories.find(cat => cat.id === this.activeSubcategory) : ''
    },
    cmpNames (a, b) {
      return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
    },
    hoverSubcategory (id) {
      this.activeSubcategory = id

      // hardcode - pokazanie kategorii ubrań w marketplace
      if (id === config.server.categoryIdsMap.marketplaceCategoryId) {
        this.$store.dispatch('category-next/fetchMenuCategories', { parent: config.server.categoryIdsMap.marketplaceClothesCategoryId })
        this.activeSubcategory = config.server.categoryIdsMap.marketplaceClothesCategoryId
      }
      // hardcode end

      this.activeId = id
      this.$store.dispatch('category-next/fetchMenuCategories', { parent: id })
    },
    categoryLink (category) {
      return formatCategoryLink(category)
    },
    notIncludedInMenu (categoriesMapForActiveSubcategory) {
      if (!categoriesMapForActiveSubcategory) return
      return categoriesMapForActiveSubcategory.filter(thirdLevel => thirdLevel.include_in_menu !== 0)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$black: color(black);

.consulting {
  @media (max-width: 1024px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  font-size: 14px;
}
.language {
  align-items: center;
}
.header-section {
  padding-bottom: 5px;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  margin-left: 0;
}
.menu__item {
  display: flex;
  font-size: 14px;
  letter-spacing: 1px;
  color: #141E39;
  @media (max-width: 991px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.item-hover:after {
  display: block;
  content: '';
  border-bottom: solid 1px #141E39;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}
.item-hover{
  display:inline-block;
  margin:0;
  text-transform:uppercase;
}
.item-hover::after{
  transform-origin: 0% 50%;
}
.item-hover:hover:after {
  transform: scaleX(1); transform-origin: 0% 50%;
  }
.icon-size {
  font-size: 8px;
}
.special-case {
  padding-bottom: 15px;
}
.consulting_menu {
  background-color: #ffffff;
  top: 80px;
  width: 100%;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  .consulting_item {
    text-align: center;
    padding: 10px;
    .link {
      color: black;
      font-size: 16px;
      margin: -10px;
      &:hover {
        color: $black;
      }
    }
  }
}
.menu__item:hover {
}
.sub-category-header {
  text-align: start;
  font-size: 12px;
  color: #141E39;
  font-weight: 600;
}
.megamenu__submenu {
  display: flex;
  box-shadow: -1px 6px 8px #78787852;
  justify-content: center;
  padding-left: 100px;
  padding-top: 2px;
  .submenu-item.first-sub {
    &:last-child {
      border-bottom-left-radius: 15px;
    }
  }
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  background-color: whitesmoke;
  top: 134px;
  display: flex;
  .submenu-item {
    display: flex;
    color:#141E39;
    font-weight: 400;
    align-items: center;
    justify-content: start;
    a {
      color:#141E39;
    }
    &:hover {
      .arrow-hover {
        opacity: 1.0;
        transform: translateX(-20px);
      }
      .menu-icon, a {
        text-decoration: underline;
        transition: all 0.35s;
      }
    }
    .menu-icon {
      position: absolute;
      top: calc(50%);
    }
  }
  .submenu-text{
    text-transform: uppercase;
    color:#141E39;
    font-size: 12px;
    font-weight: 500;
  }
}
.ubrania-text {
    color: #141E39;
    max-width: 228px;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
}
.image-box {
  overflow: hidden;
}
.brdr-bottom-right {
  border-bottom-right-radius: 15px;
}
.megamenu-image {
  width: 100%;
  max-width: 400px;
  height: auto;
}
.image-wrapper {
  display: grid;
}
.megamenu-image:hover {
  transform: scale(1.10);
  transition: all .7s;
}
.arrow-hover {
  right: -60px;
  position: absolute;
  opacity: 0;
  transition: opacity 800ms, transform 0.3s ease;
}
.brdr-bottom-left {
  border-bottom-left-radius: 15px;
}
.promo-box {
  background: white;
  border-radius: 24px;
  margin: 30px;
  width: auto;
  // height: 200px;
  overflow: hidden;
  a {
    display: flex;
  }
  .promo-img {
    height: auto;
    width: 100%;
    object-fit: contain;
    object-position: 0 0;
  }
}
</style>

<template>
  <footer id="main-footer" class="main-footer">
    <div class="footer-links footer-hide-mobile pt20 pb20 px40 bg-cl-light-white" v-show="showButtonLogic()">
      <div class="container">
        <div class="row m0">
          <div class="col-xs-12 middle-xs center-xs flex pointer" @click="toogleFooter = true">
            {{ $t('Hide footer') }}
            <i class="icon-arrow_up_contour_48x48 pl15 fs-big" />
          </div>
        </div>
      </div>
    </div>
    <div class="footer-content cl-white py30 container" v-show="showLogic()">
      <div class="center-md center-xs footer-content__top-col row p0">
        <div class="col-md-3 footer-top-side">
          <router-link :to="localizedRoute('/')" :title="$t('Home Page')" class="w-100 no-underline center-md logo-wrapper inline-flex mb30">
            <img v-lazy="'/assets/home/e-g-horisontal.svg'" alt="logo" class="logo hidden-xs" width="260" height="60">
            <img v-lazy="'/assets/home/sygnet_egarderobe.png'" alt="logo" class="logo visible-xs" width="53" height="56">
          </router-link>
          <div class="logo-text visible-xs">
            {{ $t('Join us') }}
          </div>
          <div class="media-logo-wrapper">
            <a rel="noreferrer nofollow" href="https://pl.pinterest.com/egarderobe"><img v-lazy="'/assets/home/pinterest.svg'" alt="logo" width="30px" height="30px" class="fs-large px20"></a>
            <a rel="noreferrer nofollow" href="https://www.youtube.com/channel/UChcWZZk4V8U-0H5xLHFckhQ"><img v-lazy="'/assets/home/youtube.svg'" width="30px" height="30px" alt="logo" class="fs-large px20"></a>
            <a rel="noreferrer nofollow" href="https://www.facebook.com/egarderobe/"><img v-lazy="'/assets/home/facebook.svg'" alt="logo" width="30px" height="30px" class="fs-large instagram px20"></a>
            <a rel="noreferrer nofollow" href="https://www.instagram.com/egarderobe/"><img v-lazy="'/assets/home/instagram.svg'" alt="logo" width="30px" height="30px" class="fs-large pinterest px20"></a>
            <img class="eco-svg" v-lazy="'/assets/footer/EKO_CERTYFIKAT.svg'" width="250" height="250" alt="Eko-Certyfikat">
          </div>
        </div>
        <div class="col-md-2 footer-top-side order1">
          <p class="fs-medium hidden-xs letterspace no-underline cl-white weight-600 my20 uppercase align-left">
            {{ $t('WRITE TO US') }}
          </p>
          <p class="footer-text hidden-xs pb15">
            info@e-garderobe.com
          </p>
          <p class="fs-medium column-mobile letterspace no-underline weight-600 my20 cl-white uppercase align-left">
            {{ $t('Boutique') }}
          </p>
          <p class="footer-text showroom-text column-mobile">
            {{ $t('Piękna 11A') }}
            <br>
            {{ $t('00-549 Warsaw') }}
          </p>
          <p class="fs-small hidden-xs letterspace no-underline cl-white weight-600 align-left mt20">
            {{ $t('Opening hours') }}
          </p>
          <p class="footer-text showroom-text center-xs column-mobile">
            {{ $t('Monday - Friday 11:00 - 19:00') }}
            <br>
            {{ $t('Saturday 12:00 - 16:00') }}
            <br>
            {{ $t('Sunday - closed') }}
          </p>
        </div>
        <div class="col-md-2 footer-top-side col-xs-6 column-mobile flex column">
          <router-link :to="localizedRoute('/i/' + $t('about-us'))" class="fs-medium align-left letterspace my20 no-underline weight-700 cl-white uppercase">
            {{ $t('About us') }}
          </router-link>
          <router-link :to="localizedRoute('/i/' + $t('about-us'))" class="fs-small pt20 align-left no-underline cl-white uppercase">
            {{ $t('About us') }}
          </router-link>
          <router-link :to="localizedRoute('/i/' + $t('showroom'))" class="fs-small pt20 align-left no-underline cl-white uppercase">
            {{ $t('Boutique') }}
          </router-link>
          <router-link :to="localizedRoute('/i/' + $t('how-it-works'))" class="fs-small pt20 align-left no-underline cl-white uppercase">
            {{ $t("How it works") }}
          </router-link>
          <router-link :to="localizedRoute('/prasa')" class="fs-small align-left pt20 no-underline cl-white uppercase">
            {{ $t('PRESS') }}
          </router-link>
          <router-link :to="localizedRoute('/' + $t('career'))" class="fs-small align-left pt20 no-underline cl-white uppercase">
            {{ $t('Career') }}
          </router-link>
          <router-link :to="localizedRoute('/blog')" class="fs-small align-left pt20 no-underline cl-white uppercase">
            Blog
          </router-link>
        </div>
        <div class="col-md-2 footer-top-side  col-xs-6 column-mobile flex column">
          <p class="fs-medium align-left letterspace my20 no-underline weight-700 cl-white uppercase">
            {{ $t('Help') }}
          </p>
          <router-link :to="localizedRoute('/i/' + $t('stylist-appoinment'))" class="fs-small pt20 align-left no-underline cl-white uppercase">
            {{ $t("Stylist appoitment") }}
          </router-link>
          <a :href="$t('https://faq.e-garderobe.com')" rel="noreferrer nofollow" target="_blank" class="fs-small pt20 align-left no-underline cl-white uppercase">
            {{ $t('Faq') }}
          </a>
          <router-link :to="localizedRoute('/i/' + $t('rules'))" class="fs-small pt20 align-left no-underline cl-white uppercase">
            {{ $t('Rules') }}
          </router-link>
          <router-link :to="localizedRoute('/i/' + $t('privacy-policy'))" class="fs-small pt20 align-left no-underline cl-white uppercase">
            {{ $t('Privacy policy') }}
          </router-link>
          <router-link :to="localizedRoute('/i/' + $t('contact'))" class="fs-small pt20 align-left no-underline cl-white uppercase">
            {{ $t('Contact') }}
          </router-link>
        </div>
        <div class="col-md-2 order2 column-mobile flex column">
          <p class="fs-medium align-left letterspace my20 no-underline weight-700 cl-white uppercase">
            NEWSLETTER
          </p>
          <p class="footer-text showroom-text">
            {{ $t('sign up and get a 10% discount on your first rental') }}
          </p>
          <input
            class="newsletter-input"
            id="newsletter-terms"
            type="email"
            name="email"
            autocomplete="email"
            v-model="email"
            :placeholder="$t('Twój adres email')"
            :validations="[
              {
                condition: !$v.email.required && $v.email.$error,
                text: $t('Field is required.')
              },
              {
                condition: !$v.email.email && $v.email.$error,
                text: $t('Please provide valid e-mail address.')
              }
            ]"
          >
          <button-outline color="dark" @click.native="pushNewsletter" :disabled="$v.$invalid" class="newsletter-btn">
            <span>{{ $t('Subscribe') }}</span>
            <span>➞</span>
          </button-outline>
        </div>
      </div>
      <img class="visible-xs pt20" v-lazy="'/assets/footer/EKO_CERTYFIKAT.svg'" width="100" height="100" alt="Eko-Certyfikat">
    </div>
    <div class="env-padding-bottom" v-show="hideButtonLogic()">
      <div
        class="footer-links save-margin flex pt20 pb20 px40 bg-cl-light-white"
      >
        <div class="container">
          <div class="row m0">
            <div class="col-xs-12 middle-xs center-xs flex pointer" @click="toogleFooter = false">
              {{ $t('Show footer') }}
              <i class="icon-arrow_down_contour_48x48 pl15 fs-big" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <back-to-top class="env-padding-bottom" bottom="65px" right="20px" visibleoffset="200">
      <button aria-label="Back to top" type="button" class="btn-top button no-outline brdr-none cl-white bg-cl-black :bg-cl-th-secondary py10 px10 brdr-50px flex">
        <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
          <path d="M23.245 20l-11.245-14.374-11.219 14.374-.781-.619 12-15.381 12 15.391-.755.609z" fill="white" />
        </svg>
      </button>
    </back-to-top>
  </footer>
</template>

<script>
import CurrentPage from 'theme/mixins/currentPage'
import i18n from '@vue-storefront/i18n'
import BackToTop from 'theme/components/core/BackToTop'
import ButtonOutline from 'theme/components/theme/ButtonOutline'
import { required, email } from 'vuelidate/lib/validators'

export default {
  mixins: [CurrentPage],
  name: 'MainFooter',
  data () {
    return {
      email: '',
      toogleFooter: true,
      clientService: [
        { title: i18n.t('Contact'), link: '/i/' + i18n.t('contact') }
      ],
      information: [
        { title: i18n.t('About us'), link: '/i/' + i18n.t('about-us') }
      ],
      blog: [
        { title: i18n.t('About us'), link: '/i/' + i18n.t('about-us') }
      ],
      certificates: [
        { image: '/assets/footer/certum.png', alt: 'Certum Logo' }
      ],
      shipments: [
        { image: '/assets/footer/InPost.svg', alt: 'InPost Logo' },
        { image: '/assets/shipping-logo/fedex-express.svg', alt: 'UPS Logo' },
        { image: '/assets/shipping-logo/Raben_logo.svg', alt: 'DPD Logo' }
      ],
      payments: [
        { image: '/assets/footer/google_pay.svg', alt: 'GooglePay Logo' },
        { image: '/assets/footer/apple_pay.svg', alt: 'ApplePay Logo' },
        { image: '/assets/footer/PayU.svg', alt: 'PayU Logo' },
        { image: '/assets/payments-logo/blik.svg', alt: 'PayU Logo' },
        { image: '/assets/footer/mastercard.svg', alt: 'Mastercard Logo' },
        { image: '/assets/footer/visa.svg', alt: 'Visa Logo' }
      ]
    }
  },
  beforeMount () {
    this.$bus.$on('checkout-after-load', this.onCheckoutLoad)
  },
  beforeDestroy () {
    this.$bus.$off('checkout-after-load', this.onCheckoutLoad)
  },
  methods: {
    onCheckoutLoad () {
      this.toogleFooter = true
    },
    pushNewsletter () {
      window.dataLayer && window.dataLayer.push({
        event: 'subscribe',
        eventAction: 'subscribe',
        eventCategory: 'newsletter',
        eventLabel: 'footer',
        customer_tags: 'footer',
        'user-email': this.email
      })
      this.notifyUser('Dziękujemy za zapisanie się na nasz newsletter.')
    },
    notifyUser (notificationData) {
      this.$store.dispatch('notification/spawnNotification', {
        type: 'success',
        message: notificationData,
        action1: { label: this.$t('OK') }
      }, { root: true })
    },
    showButtonLogic () {
      if (this.isCheckoutPage) {
        return !this.toogleFooter
      } else if (this.$device.isMobile || this.$device.isTablet) {
        return (this.isProductPage || this.isCategoryPage || this.isMyAccount || this.isHomePage || this.isThankYouForSellingPage || this.isCmsPage || this.isSellingPage) && !this.toogleFooter
      } else {
        return false
      }
    },
    hideButtonLogic () {
      if (this.isCheckoutPage) {
        return this.toogleFooter
      } else if (this.$device.isMobile || this.$device.isTablet) {
        return (this.isProductPage || this.isCategoryPage || this.isMyAccount || this.isHomePage || this.isThankYouForSellingPage || this.isCmsPage || this.isSellingPage) && this.toogleFooter
      } else {
        return false
      }
    },
    showLogic () {
      if (this.isCheckoutPage) {
        return !this.toogleFooter
      } else if (this.$device.isMobile || this.$device.isTablet) {
        return (this.isProductPage || this.isCategoryPage || this.isMyAccount || this.isHomePage || this.isThankYouForSellingPage || this.isCmsPage || this.isSellingPage) && !this.toogleFooter
      } else if (!(this.isProductPage || this.isCategoryPage || this.isMyAccount || this.isHomePage || this.isThankYouForSellingPage || this.isCmsPage || this.isSellingPage)) {
        return this.toogleFooter
      } else {
        return true
      }
    }
  },
  components: {
    BackToTop,
    ButtonOutline
  },
  validations: {
    email: {
      required,
      email
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~theme/css/variables/colors";
@import "~theme/css/helpers/functions/color";
$gray: color(gray);

.tablet-column {
  flex-basis: 16.66666667%;
  max-width: 16.66666667%;
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px)  {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  @media (max-width: 767px) {
    flex-basis: 50%;
    max-width: 50%;
  }
}
.filter-white {
  filter: brightness(0) invert(1);
}
.mobile-font {
  @media (max-width: 767px) {
    font-size: 16px !important;
    font-weight: 900 !important;
  }
}
.logo-wrapper {
  justify-content: center;
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
}
.li-item {
  margin-bottom: 20px;
  @media (max-width: 767px) {
    margin-bottom: 5px;
  }
}
.li-item-blog {
  margin-bottom: 15px;
  @media (max-width: 767px) {
    margin-bottom: 10px;
  }
}
.li-title {
  font-size: 18px;
  margin-bottom: 50px;
  @media (max-width: 767px) {
    font-size: 16px;
    font-weight: 900;
    margin-bottom: 20px;
  }
}
.footer-hide-mobile {
  display: flex;
  @media (max-width: 767px) {
    display: none;
  }
}
.margin-mobile {
  @media (max-width: 767px) {
    margin-bottom: 20px;
  }
}
.tel {
  font-size: 24px;
  @media (max-width: 767px) {
    font-size: 16px;
  }
}
.info-label {
  font-size: 24px;
  @media (max-width: 767px) {
    font-size: 18px;
    margin-bottom: 5px;
  }
}
.text {
  font-size: 14px;
  @media (max-width: 767px) {
    font-size: 12px;
  }
}
.logo-border {
  border: 1px solid $gray;
  border-radius: 5px;
}
.letterspace {
  letter-spacing: 2px;
}
.newsletter-input {
    margin-top: 40px;
    background: #2d2d2d;
    height: 20px;
    margin-bottom: 46px;
    border-top: none;
    color: white;
    border-left: none;
    border-right: none;
    border-bottom: 3px solid white;
    padding-bottom: 5px;
    @media (max-width: 767px) {
      background: black;
      width: 75%;
      text-align: center;
  }
}
.arrow {
  bottom: 2px;
  right: 10px;
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
}
.up {
  transform: rotate(225deg);
}
.logo-text {
 text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: 13px;
    padding-bottom: 25px;
}
.radius-footer {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.button-newsletter {
    padding-top: 5px;
    height: 20px;
    border-left: none;
    border-right: none;
    border-bottom: 3px solid white;
    text-transform: uppercase;
    width: 100px;
    font-size: 12px;
    font-weight: 700;
    padding-bottom: 5px;
}
.newsletter-content {
  align-content: center;
  @media (max-width: 1667px) {
    flex-direction: column;
  }
}
.showroom-text {
  @media (max-width: 767px) {
    width: 60%;
    margin: auto;
  }
}
.order1 {
  @media (max-width: 767px) {
    order: 1
  }
}
.order2 {
  @media (max-width: 767px) {
    order: 2
  }
}
.footer-text {
  font-size: 10px;
  letter-spacing: 1px;
  text-align: start;
  font-weight: 300;
  line-height: 2;
}
.eco-svg {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 160px;
  @media (max-width: 767px) {
    display: none;
  }
}
.newsletter-btn {
    display: flex;
    background-color: #2d2d2d !important;
    text-transform: uppercase;
    color: white !important;
    border-radius: 0;
    text-align: left;
    -webkit-box-shadow: none !important;
    box-shadow: none;
    min-width: 100px;
    padding-left: 0;
    width: 35px;
    padding-right: 0;
    border-bottom: 3px solid white;
    justify-content: space-between;
    @media (max-width: 767px) {
      background-color: black !important;
    }
}
.newsletter-btn:hover {
  background-color: #2d2d2d;
  @media (max-width: 767px) {
    background-color: black
  }
}
.column-mobile {
  @media (max-width: 767px) {
    align-items: center;
    text-align: center;
    padding: 0;
  }
}
#main-footer {
  background-color: #2d2d2d;
  @media (max-width: 767px) {
    background-color: black;
  }
}
.footer-content {
  display: flex;
  background-color: #2d2d2d;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 767px) {
    background-color: black;
  }
}
.logo {
  filter: brightness(0) invert(1);
}
.footer-content__top-col {
    width: 100%;
}
.media-logo-wrapper {
  @media (max-width: 767px) {
    text-align: center;
  }
}
.footer-top-side {
  @media (max-width: 767px) {
    border-bottom: 1px solid #393939;
    padding-bottom: 30px;
  }
}
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px), (max-width: 1125px) {
  .footer-links {
    padding: 20px 20px 30px 20px
  }
  .save-margin {
    margin-bottom: 0px;
  }
}
</style>

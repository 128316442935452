import config from 'config'
import i18n from '@vue-storefront/i18n'

export default {
  title: i18n.t('E-Garderobe, wypożyczalnia sukienek na zbliżającą się okazję'),
  htmlAttrs: {
    lang: 'pl_PL'
  },
  meta: [
    { charset: 'utf-8' },
    { vmid: 'description', name: 'description', content: i18n.t('Wypożycz sukienkę, garnitur do pracy i rzeczy na co dzień. Abonament modowy od 150 zł/msc. Sukienki na wesele, komunię, sylwestra i urodziny. Ekspresowa wysyłka') },
    { name: 'viewport', content: 'width=device-width, initial-scale=1, user-scalable=no, minimal-ui, viewport-fit=cover' },
    { name: 'robots', vmid: 'robots', content: config.server.useNoIndexNoFollow ? 'noindex, nofollow' : 'index, follow' },
    { name: 'mobile-web-app-capable', content: 'yes' },
    { name: 'theme-color', content: '#ffffff' },
    { name: 'apple-mobile-web-app-status-bar-style', content: '#ffffff' },
    { vmid: 'og:locale', property: 'og:locale', content: 'pl_PL' },
    { vmid: 'og:site_name', property: 'og:site_name', content: 'e-garderobe.com' },
    { vmid: 'og:title', property: 'og:title', content: i18n.t('E-Garderobe, wypożyczalnia sukienek na zbliżającą się okazję') },
    { vmid: 'og:image', property: 'og:image', content: 'https://e-garderobe.com/assets/' + i18n.t('facebook.png') },
    { vmid: 'og:description', property: 'og:description', content: i18n.t('Wypożycz sukienkę, garnitur do pracy i rzeczy na co dzień. Abonament modowy od 150 zł/msc. Sukienki na wesele, komunię, sylwestra i urodziny. Ekspresowa wysyłka') },
    { vmid: 'og:url', property: 'og:url', content: 'https://e-garderobe.com/' },
    { property: 'fb:app_id', content: '428642937677523' }
  ],
  link: [
    { rel: 'icon', type: 'image/png', href: '/assets/favicon-48x48.png', sizes: '48x48' },
    { rel: 'icon', type: 'image/png', href: '/assets/favicon-32x32.png', sizes: '32x32' },
    { rel: 'icon', type: 'image/png', href: '/assets/favicon-16x16.png', sizes: '16x16' },
    { rel: 'icon', type: 'image/png', href: '/assets/favicon.ico', sizes: '16x16' },
    { rel: 'apple-touch-icon', href: '/assets/apple-touch-icon.png' },
    { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_2048.png', sizes: '2048x2732' },
    { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_1668.png', sizes: '1668x2224' },
    { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_1536.png', sizes: '1536x2048' },
    { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_1125.png', sizes: '1125x2436' },
    { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_1242.png', sizes: '1242x2208' },
    { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_750.png', sizes: '750x1334' },
    { rel: 'apple-touch-startup-image', href: '/assets/apple_splash_640.png', sizes: '640x1136' },
    { rel: 'manifest', href: '/assets/manifest.json' },
    { rel: 'stylesheet', as: 'style', href: 'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,400;1,300&display=swap' },
    // { rel: 'stylesheet', as: 'style', href: 'https://fonts.googleapis.com/css2?family=Anglecia+Pro+Displaye&display=swap' },
    { rel: 'stylesheet', as: 'style', href: 'https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;700&display=swap' },
    { rel: 'preconnect', href: 'https://fonts.gstatic.com/', crossorigin: 'anonymous' }
  ],
  script: [
    {
      src: 'https://www.googleoptimize.com/optimize.js?id=OPT-KLT29QK',
      async: true,
      crossorigin: 'anonymous'
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/pwacompat@2.0.17/pwacompat.min.js',
      async: true,
      crossorigin: 'anonymous'
    }
  ],
  noscript: [
    { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600|Krona+One&display=swap' }
  ]
}

<template>
  <router-link :to="localizedRoute('/')" :title="$t('Home Page')" class="no-underline inline-flex logo-container">
    <img src="/assets/home/e-g-horisontal.svg" class="py10" alt="logo" width="160" height="30">
  </router-link>
</template>

<script>
import config from 'config'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export default {
  data () {
    const storeView = currentStoreView()
    return {
      defaultTitle: storeView.seo.defaultTitle ? storeView.seo.defaultTitle : config.seo.defaultTitle
    }
  },
  props: {
    width: {
      type: [String, Number],
      required: true
    },
    height: {
      type: [String, Number],
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.logo-container {
  @media only screen and (max-width:1024px) {
    width: 75%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    height: 44px;
    align-items: center;
  }
}
</style>

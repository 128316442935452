<template>
  <div class="hooper-container align-center cl-white">
    <no-ssr>
      <hooper
        v-if="validBaners['infoBarHeader']"
        :auto-play="true"
        :play-speed="2000"
        :infinite-scroll="true"
        :keys-control="false"
        :center-mode="true"
      >
        <slide class="flex middle-xs center-xs" v-for="(label, index) in validBaners['infoBarHeader']" :key="index">
          {{ label.text }}
        </slide>
        <hooper-navigation slot="hooper-addons" class="hidden-xs" />
      </hooper>
    </no-ssr>
  </div>
</template>

<script>
import NoSSR from 'vue-no-ssr'
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper'
import 'hooper/dist/hooper.css'

export default {
  name: 'TopBar',
  components: {
    Hooper,
    Slide,
    HooperNavigation,
    'no-ssr': NoSSR
  },
  props: {
  },
  data () {
    return {
      validBanners: {},
      isLoad: false
    }
  },
  mounted () {
    if (!this.validBaners['infoBarHeader']) {
      this.$store.dispatch('cmsBlock/list', { filterValues: null })
    }
  },
  computed: {
    validBaners () {
      return this.$store.state.cmsBlock.baners
    }
  }
}
</script>

<style lang="scss">
.hooper-container {
  .hooper {
    height: 100%;
    &:focus {
      outline: none;
    }
   .hooper-list {
      &:focus {
        outline: none;
      }
    }
    .hooper-track {
      &:focus {
        outline: none;
      }
    }
    .hooper-next {
      display: none;
    }
    .hooper-prev {
      display: none;
    }
    .hooper-slide {
      border: none !important;
      outline: none !important;
      &:focus {
        outline: none;
        border: none;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.hooper-container {
  font-size: 0.8em;
  height: 100%;
  @media (min-width: 768px) {
    width: 650px;
    margin: 0 auto;
  }
  &:focus {
    outline: none;
  }
}
</style>

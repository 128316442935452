<template>
  <button
    type="button"
    class="flex middle-xs bg-cl-transparent brdr-none relative wishlist-btn px0"
    :class="isWishlist ? 'cl-main' : 'colors'"
    @click="toggleWishlistPanelCustom"
    data-testid="wishlist-icon"
    :aria-label="$t('Open wishlist')"
  >
    <i class="icon-heart_contour icon-size" />
    <span
      class="whishlist-count absolute flex center-xs middle-xs border-box py0 px2 h6 lh16 weight-700 cl-white bg-cl-main"
      v-show="getWishlistItemsCount"
    >
      {{ getWishlistItemsCount }}
    </span>
  </button>
</template>

<script>
import { mapState } from 'vuex'
import WishlistIcon from '@vue-storefront/core/compatibility/components/blocks/Header/WishlistIcon'

export default {
  mixins: [WishlistIcon],
  computed: {
    ...mapState({
      isWishlist: state => state.ui.wishlist,
      isAuthElem: state => state.ui.isAuthElem
    })
  },
  methods: {
    toggleWishlistPanelCustom () {
      if (this.isAuthElem) {
        this.$bus.$emit('modal-hide', 'modal-signup')
      }
      this.$store.dispatch('ui/toggleWishlist')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$gray: color(gray);
.colors {
  color: black;
}
.whishlist-count {
  top: 1px;
  left: 22px;
  min-width: 16px;
  min-height: 16px;
  border-radius: 10px;
}
</style>

<template>
  <button
    type="button"
    class="relative bg-cl-transparent brdr-none flex middle-xs microcart-btn px0"
    :class="isMicrocart ? '' : 'colors'"
    @click="openMicrocart"
    data-testid="openMicrocart"
    :aria-label="$t('Open microcart')"
  >
    <i class="icon-cart microcart-btn__icon icon-size" />
    <span
      class="minicart-count absolute flex center-xs middle-xs border-box py0 px2 h6 lh16 weight-700 cl-white bg-cl-main"
      v-cloak
      v-show="totalQuantity"
      data-testid="minicartCount"
    >
      {{ totalQuantity }}
    </span>
  </button>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import MicrocartIcon from '@vue-storefront/core/compatibility/components/blocks/Header/MicrocartIcon'
import { syncCartWhenLocalStorageChange } from '@vue-storefront/core/modules/cart/helpers'

export default {
  mounted () {
    syncCartWhenLocalStorageChange.addEventListener()
    this.$once('hook:beforeDestroy', () => {
      syncCartWhenLocalStorageChange.removeEventListener()
    })
  },
  computed: {
    ...mapState({
      isMicrocart: state => state.ui.microcart,
      isAuthElem: state => state.ui.isAuthElem
    }),
    ...mapGetters({
      totalQuantity: 'cart/getItemsTotalQuantity'
    })
  },
  methods: {
    openMicrocart () {
      if (this.isAuthElem) {
        this.$bus.$emit('modal-hide', 'modal-signup')
      }
      this.$store.dispatch('ui/toggleMicrocart')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
$gray: color(gray);

.colors {
  color: black;
  @media (max-width: 1024px) {
    color: $gray;
  }
}
.minicart-count {
  top: 1px;
  left: 20px;
  min-width: 16px;
  min-height: 16px;
  border-radius: 10px;
}
.microcart-btn {
  @media (max-width: 1024px) {
    flex-direction: column;
  }
  &__text {
    font-size: 14px;
    padding-left: 5px;
    @media (max-width: 1024px) {
      font-size: 12px;
      padding: 0;
    }
  }
}
</style>
